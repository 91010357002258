<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('grade_entry')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('grade_entry')" :isFilter="false" />
            </template>
            <b-row>
                <b-col md="3">
                    <b-card bg-variant="light" class="text-center cursor-pointer" @click="$router.push('/grade/entry')">
                        <b-card-text>
                            {{$t('final_grade_entry').toUpper()}}
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col md="3">
                    <b-card bg-variant="light" class="text-center cursor-pointer" @click="$router.push('/make-up/exam/letter/entry')">
                        <b-card-text>
                            {{$t('makeup_letter_entry').toUpper()}}
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t("grade_entry")
        }
    },
    data() {
        return {

        }
    },
    methods: {

    },


}
</script>

<style scoped>
    .cursor-pointer {
        cursor: pointer;
    }
</style>
